<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <img :src="importedImg1" :alt="altText" class="imgItem1" />
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>PRIVACY AGREEMENT</h1>
       <!-- <div class="title-line"></div> -->
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/kspbawanaartha/components/img/4_1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`For this privacy policy, the user of the service may be a customer/consumer, or any other person who uses the service or accesses our platform.
If you do not agree with this policy or any part of it, please do not use or access any part of our platform.
1. Collect personal identity information
When you use our applications, we will explicitly request your permission to collect and store the information (personal information) that you provide. Our main goal is to provide you with a safe, efficient, seamless and personalized experience and service. This allows us to provide services and features that meet your needs and to customize our platform to make your experience safer and easier, and to improve the services we provide. Most importantly, we collect personal information from you that we believe is necessary to achieve the purposes mentioned above and provide you with services based on this information.
Generally, you can browse websites or applications without telling us who you are and without revealing any personal information about yourself. However, to create an account on a website or application, you must provide us with certain basic information necessary for us to provide you with personalized services. The information we collect from you especially includes: a. Your full name; b. Home; c. PIN code; d. Family data; and. Personal income; F. Phone number; g. Permanent account number; h. Gender i. Marital status j. Family details k. Commercial information and other information shared through application forms or emails or other methods.
We will indicate the required and optional fields. By opting out of using specific services or features on the Platform, you can always choose not to provide your personal information. When you create a free account on our platform, we will collect other identifiable information from you. Although you can browse certain parts of the Platform without registering as described above, certain activities (such as obtaining loans from third-party lenders on the Platform) require registration and require you to provide the above details. The Platform must clearly display the personal information it collects from you, and you may choose not to provide such personal information. However, this will limit the services provided to you on the platform. This move is not only to prevent fraudulent users from causing losses to our platform, but also to allow us to score your credit, in order to confirm the analysis and servicing of your loan.
2. Collect contact information
As part of our lending process, we ask loan applicants to provide references. In this sense, during the submission of the form in our application, we collect your contact information to detect close contacts, allowing you to automatically complete the data during the loan application process.
Additionally, we collect contact information from your device for risk analysis by detecting trusted reference information. The more credible the referral, the lower the risk associated with the user.
3.SMS
We only monitor financial SMS (4-digit financial SMS), including sender, description and sent date in the user's inbox, which helps us identify the various accounts the user owns and the flow patterns of cash held by the user to help us determine the user's risk profile and provide an appropriate credit analysis for the user. Additionally, we collect and process telephone data such as SMS logs for risk analysis and to evaluate a user's eligibility to use our products or services (including, but not limited to, assessing the user's creditworthiness, determining whether the user may pay for the products or services requested by the user and/or determining whether the user is eligible for additional benefits from the products available); to provide loan services to users (including, but not limited to, processing their transactions and processing the user's loan payments and collecting the user's loan repayments); verify the identity of users and/or other information provided to us by users; detect, combat and prevent fraud, attempted fraud, money laundering and/or other illegal use of our services; analyze customer behavior; interact with credit reference or fraud prevention agencies; and provide customer service or support.
To contact you via text messages (SMS) (if applicable) in accordance with the purposes described in this Privacy Policy.
All SMS data will be encrypted and uploaded to our servers through the app, but we will filter and delete personal SMS data from our servers and will never share this data with third parties.
4.Camera
Allows the app to obtain camera permissions to capture images/videos and thus speed up the loan application process.
5.Location
Allows the app to obtain location permissions to provide personalized offers.
6.Cellular
We will use the code (IMEl) of your cell phone, since with it we can ensure that other cell phones cannot replace it when requesting a loan, thus preventing fraud.
7. Purpose of information collection
We collect personal data to achieve the following purposes:
to. Comply with the contractual relationship derived from the service request and/or contract.
b. In accordance with the applicable regulations of the service subscribed to us, verify and confirm the identity of the owner and the veracity of the information provided (such as your own reference and joint recognition)
c. Manage, operate and monitor the services you request or contract with us.
d. Analyze the information provided by the owner to determine the level of risk, the ability to pay, the status of the transaction and the evaluation of the services provided in accordance with current laws and regulations.
and. Formally confirm the contractual relationship between us and the owner.
F. Include it in our database, directly or through third-party integration files.
g. Process your request electronically or by telephone.
h. Create personal information as a user in the application to verify and investigate reputation.
Yo. Consult and conduct a credit investigation with a credit information company that you believe is appropriate.
j. Provide extensions or improvements to the services stipulated in the contract.
k. Notify us of updates.
l. Conduct business intelligence, conduct market research, understand your activities on our electronic media, monitor the quality of our electronic media, evaluate and improve our electronic media.
8. Use your information
We use the information you provide to us to establish an identity and verify it in the following ways;
to. Monitor, improve and manage our website/platform;
b. Provide our services, that is, carry out credit analysis to facilitate the provision of loans to you.
c. Design and provide customized products and services provided by our third party financial partners;
d. Analyze the method of use of the website, diagnose technical or service problems and maintain security;
and. Send communication notifications, information about the products or services you request, or process your queries and applications on the website;
F. Manage our relationship with you and inform you about other products or services we believe you may find certain uses;
g. Perform data analysis to improve the services/products provided to users;
h. Use user information to comply with national laws and regulations;
Yo. Perform KYC for our third party lending partners based on information shared by users;
j. Use user information in other ways permitted by law to enable you to obtain financial services from our lending partners.
We will use and retain your information for the period necessary to provide you with services on the website, comply with our legal obligations, resolve disputes and implement our agreements.
9. Disclosure of your information
We will only share your information with our registered third parties (including our regulated financial partners) to provide services on the website and/or provide loans/facilitation to users.
to. We may disclose and share your information with financial services providers, banks and our third-party partners to promote loans or financing, lines of credit or use of products;
b. If we are required to do so to comply with any legal obligation or governmental and/or statutory agency order, or to implement or apply our information without notice, we may disclose your information without notice in the process of divestiture, merger or protection of the rights, property or safety of us, our users or others, using the terms of use or distribution of such information. This includes sharing information with other companies and organizations to prevent fraud and reduce credit risk.
c. We disclose information to our third-party credit and technology partners for credit checks and analysis, such as credit agencies or third-party data source providers;
d. We will share your information with third parties pursuant to a confidentiality agreement and will restrict the third parties' use of the information only to the purposes detailed here. We guarantee that we will not share unauthorized disclosure of your information with third parties.
10. Confidentiality of user information
The application is designed to protect your personal information and maintain the accuracy confirmed by you. We implement reasonable physical, administrative and technical safeguards to help us protect your personal information from unauthorized access, use and disclosure. For example, when we transmit such sensitive information over the Internet, we encrypt it. We also require our registered third-party service providers to protect such information from unauthorized access, use and disclosure.
Our platform has adopted strict security measures to protect the loss, misuse and alteration of controlled information. We strive to maintain and ensure the security of the information you provide to us. We use Secure Sockets Layer (SSL)-based encryption to transmit information, according to the law, this is the level of encryption currently required.
We integrate multi-step security within the product with the most advanced technology to ensure our system maintains strong security measures, and the overall data security and privacy design allows us to defend against low-level issues all the way to complex attacks.
11. The right to privacy of minors
This app is not suitable for anyone under the age of 18 or under in your jurisdiction. We do not knowingly collect personally identifiable information from children under 18 years of age. If you are a minor, uninstall this app immediately
12. Changes to this privacy policy
We may review and modify this privacy policy at our sole discretion from time to time to ensure that it is consistent with our future development and/or changes in laws or regulations. If we decide to modify this privacy policy, we will notify you of such modifications through general notices posted on the application and/or website, or through the email address you established in your account. You agree that it is your responsibility to regularly check this privacy policy for the latest information on our data protection and processing practices, and it is your responsibility to continue using the application or after using the application or website, to contact us or use the service website. Any modification to this privacy policy will constitute your acceptance of the modification.
13. Confirm and accept
Accepting the privacy policy means that you confirm that you have read and understood this privacy policy and accept all its terms. In particular, you acknowledge and agree to our collection, use, disclosure, storage, protection and sharing.
14.Controls over your privacy
You have the rights to:
1. Access your personal data held by the Platform;
2. Revoke consent for the processing of your personal data, review and/or delete personal data in possession of the Platform unless the conservation of said data is required by laws and regulations, without retroactive effects, in all cases in which which said revocation, revision and deletion do not imply the impossibility of complying with the obligation derived from a current legal relationship between you and the Platform or when it comes to non-necessary purposes.`,
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #F2FCF7;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 4800px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: -140px auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #3F4842;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block;
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.imgBox2{
  transform: scale(90%);
  margin: -280px auto 50px;
  height: 3700px;
  box-shadow:0 6px 14px rgba(0, 0, 0, 0.25);
}

.title-line{
  width: 430px;
  /* background: url(./components/img/Group1.png); */
  /* background-repeat: no-repeat; */
  /* background-position: -150px 10px; */
  /* height: 50px; */
  /* border: 1px solid red; */
  border: 5px solid #0BAF60;
  margin: 0 500px;
}
</style>